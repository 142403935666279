<!-- 结算单创建 -->
<template>
  <Modal v-model="showFeeitemsModal" width="1000" :closable="false">
    <Row class="p-b-5" :gutter="8" v-if="query.feeitemType === 1">
      <i-col span="6">
        <DatePicker
          placeholder="记账起始日期"
          type="date"
          v-model="transactionStartDate"
          size="small"
          style="width: 100%"
          format="yyyy-MM-dd"
        ></DatePicker>
      </i-col>
      <i-col span="6">
        <DatePicker
          placeholder="记账结束日期"
          type="date"
          size="small"
          v-model="transactionEndDate"
          placement="bottom-end"
          style="width: 100%"
          format="yyyy-MM-dd"
        ></DatePicker>
      </i-col>
      <i-col span="8">
        <i-input
            v-model="keyword"
            size="small"
            clearable
            placeholder="关键字：支持客户名称、品牌名称、合同编号、产品名称等"
          ></i-input>
      </i-col>

      <i-col span="4">
        <Button
          type="primary"
          size="small"
          icon="ios-search"
          @click="reloadList"
        >
        搜索
        </Button>
      </i-col>
    </Row>
    <Row class="p-b-5" :gutter="8" v-if="query.feeitemType === 2">
      <i-col span="3">
        <Select v-model="createCompanyId" size="small"  placeholder="代理商">
            <Option
              v-for="(company, index) in CreateCompanyArray"
              :key="index"
              :value="company.id"
              >{{ company.name }}</Option
            >
          </Select>
      </i-col>
      <i-col span="3">
        <Select v-model="assetIds" size="small" clearable multiple placeholder="资产">
          <Option
            v-for="(asset, index) in assetList"
            :key="index"
            :value="asset.id"
            >{{ asset.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="3">
        <Select v-model="mspTaskTypes" size="small" clearable  multiple placeholder="作业类型">
          <Option
            v-for="(type, index) in taskitemTypeArray"
            :key="index"
            :value="type.taskitemType"
            >{{ type.taskitemTypeText }}</Option
          >
        </Select>
      </i-col>
      <i-col span="4">
          <DatePicker
                placeholder="作业完成周期"
                type="daterange"
                size="small"
                 clearable
                v-model="actionDate"
                style="width: 100%"
                format="yyyy-MM-dd"
              ></DatePicker>
        </i-col>
        <i-col span="4">
          <DatePicker
                placeholder="质检周期"
                type="daterange"
                size="small"
                 clearable
                v-model="qualityCheckDate"
                style="width: 100%"
                format="yyyy-MM-dd"
              ></DatePicker>
        </i-col>
        <i-col span="4">
          <DatePicker
                placeholder="记账周期"
                type="daterange"
                size="small"
                 clearable
                v-model="transactionDate"
                style="width: 100%"
                format="yyyy-MM-dd"
              ></DatePicker>
        </i-col>
      <i-col span="3">
        <Button
              type="primary"
              size="small"
              icon="ios-search"
              @click="reloadList"
            >
              搜索
            </Button>
      </i-col>
    </Row>

    <Table
      stripe
      border
      :data="list"
      :height="500"
      :columns="tableColumns"
      @on-selection-change="selectionChange"
    ></Table>

    <Row>
      <i-col span="12">
        <span style="height: 28px; line-height: 28px"
          >已选中{{ chkfeeitemIds.length }}条</span
        >
      </i-col>
      <i-col span="12">
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            :current="query.pageNumber"
            @on-change="handlePageChanged"
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            @on-page-size-change="handlePageSizeChanged"
          ></Page>
        </div>
      </i-col>
    </Row>

    <div slot="footer">
      <Button type="text" @click="showFeeitemsModal = false">取消</Button>
      <Button type="primary" @click="confirm">确认</Button>
    </div>
  </Modal>
</template>
<script>
import { toMoney } from '@/utils/wnumb_own'
import {
  getfeeitempage,
  batchaddorremovefeeitemlist,
  getmspfeeitempage,
  listMspCreateCompany
} from '@/api/statement/bill'
import { ParseDate, GetCurrentSchedule } from '@/utils/dateFormat'
import { getMaintenanceAssetList } from '@/api/rim/asset'

import {
  gettaskitemtypelist
} from '@/api/msp/settlement'

export default {
  data () {
    return {
      showFeeitemsModal: false,
      companyArray: [],
      assetList: [],
      taskitemTypeArray: [],
      total: 0,
      lastPage: true,
      billId: 0,
      assetIds: [],
      mspTaskTypes: [],
      query: {
        feeitemType: 1,
        agentCompanyId: 0,
        billStatus: -1,
        pageSize: 15,
        pageNumber: 1
      },
      transactionStartDate: '',
      transactionEndDate: '', // 交易结算查询时间
      keyword: '',
      transactionDate: [],
      actionDate: [],
      qualityCheckDate: [],
      list: [],

      tableColumns: [],
      feeitemColumn: [
        {
          type: 'selection',
          key: 'feeitemId',
          width: 60,
          align: 'center'
        },
        {
          title: '合同编号',
          key: 'contractCode'
        },
        {
          title: '客户',
          key: 'advertiserName'
        },
        {
          title: '品牌',
          key: 'brandName',
          render: (h, params) => {
            return h('div', [h('p', params.row.brandName || '-')])
          }
        },
        {
          title: '产品名称',
          key: 'goodName',
          render: (h, params) => {
            return h('div', [h('p', params.row.goodName || '-')])
          }
        },
        {
          title: '合同总额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.contractAmount)
              )
            ])
          }
        },

        {
          title: '执行周期',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                GetCurrentSchedule(params.row.startDate, params.row.endDate)
              )
            ])
          }
        },
        {
          title: '执行金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.periodExecutedAmount)
              )
            ])
          }
        }
      ],
      mspfeeitemColumn: [
        {
          type: 'selection',
          key: 'feeitemId',
          width: 60,
          align: 'center'
        },
        {
          title: '供应商',
          key: 'companyName',
          render: (h, params) => {
            return h('div', [h('p', params.row.supplierCompanyName)])
          }
        },
        {
          title: '代理商',
          key: 'createCompanyName',
          render: (h, params) => {
            return h('div', [h('p', params.row.createCompanyName)])
          }
        },
        {
          title: '资源编号',
          key: 'resourceCode',
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceCode)])
          }
        },
        {
          title: '资产',
          key: 'code',
          render: (h, params) => {
            return h('div', [h('p', params.row.assetName)])
          }
        },
        {
          title: '位置',
          key: 'position',
          render: (h, params) => {
            return h('div', [h('p', params.row.position)])
          }
        },
        {
          title: '资源类型',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceTypeName)])
          }
        },
        {
          title: '用途',
          key: 'adTypeName',
          render: (h, params) => {
            return h('div', [h('p', params.row.adTypeName || '-')])
          }
        },
        {
          title: '作业类型',
          key: 'taskTypeName',
          render: (h, params) => {
            return h('div', [h('p', params.row.taskTypeName)])
          }
        },
        {
          title: '记账金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '派单时间',
          key: 'supplierDispatchTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.supplierDispatchTime)])
          }
        },
        {
          title: '作业日期',
          key: 'taskRequireFinishTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.taskRequireFinishTime)])
          }
        },
        {
          title: '质检时间',
          key: 'qualityCheckTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.qualityCheckTime)])
          }
        },
        {
          title: '记账时间',
          key: 'confirmTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.confirmTime)])
          }
        }
      ],

      currentPageAllItemIds: [],
      chkfeeitemIds: [],
      mark: '',
      CreateCompanyArray: [],
      createCompanyId: []
    }
  },
  methods: {
    initfeeitemColumn () {
      const that = this
      if (that.query.feeitemType === 1) {
        that.tableColumns = that.feeitemColumn
      } else {
        that.tableColumns = that.mspfeeitemColumn
      }
    },
    showModal (feeitemType, billDetail, billId) {
      this.list = []
      this.chkfeeitemIds = []
      this.billId = billId
      this.query.pageNumber = 1
      this.query.feeitemType = feeitemType
      if (feeitemType === 1) {
        this.query.agentCompanyId = billDetail.agentCompanyId
      } else if (feeitemType === 2) {
        this.query.purchaserCompanyId = billDetail.purchaserCompanyId
        this.query.supplierCompanyId = billDetail.supplierCompanyId

        this.initAssetArray()
        this.inittaskitemTypeArray()
        this.initsettlementCompanyArray()
      }
      this.query.startDate = null
      this.query.endDate = null

      this.initfeeitemColumn()
      this.showFeeitemsModal = true
      if (billDetail) {
        this.initItemArray()
      }
    },
    initsettlementCompanyArray () {
      listMspCreateCompany({ publisherId: this.publisherId }).then(res => {
        this.CreateCompanyArray = res
      })
    },
    // 加载作业类型
    inittaskitemTypeArray () {
      const that = this
      that.taskitemTypeArray = []

      gettaskitemtypelist({
      }).then((res) => {
        that.taskitemTypeArray = res.filter(x => parseInt(x.taskitemType) > 0)
      })
    },
    // 加载所有可选线路
    initAssetArray () {
      getMaintenanceAssetList().then((res) => {
        this.assetList = res
      })
    },
    // 加载所有可结算的交易项
    initItemArray () {
      const that = this
      if (that.query.feeitemType === 1) {
        if (that.transactionStartDate) {
          that.query.startDate = ParseDate(this.transactionStartDate)
        } else {
          that.query.startDate = null
        }
        if (that.transactionEndDate) {
          that.query.endDate = ParseDate(this.transactionEndDate)
        } else {
          that.query.endDate = null
        }
        that.query.keyword = that.keyword
        getfeeitempage(this.query).then((res) => {
          res.list.map((item) => {
            item._checked = that.chkfeeitemIds.includes(item.feeitemId)
            return item
          })
          that.currentPageAllItemIds = res.list.map((item) => {
            return item.feeitemId
          })
          that.list = res.list
          that.total = res.totalRow
          that.lastPage = res.lastPage
        })
      } else if (that.query.feeitemType === 2) {
        if (this.transactionDate.length) {
          this.query.confirmStartDate = this.transactionDate[0] ? ParseDate(this.transactionDate[0]) : null
          this.query.confirmEndDate = this.transactionDate[1] ? ParseDate(this.transactionDate[1]) : null
        } else {
          this.query.confirmStartDate = null
          this.query.confirmEndDate = null
        }

        if (this.actionDate.length) {
          this.query.deliveryFinishStartDate = this.actionDate[0] ? ParseDate(this.actionDate[0]) : null
          this.query.deliveryFinishEndDate = this.actionDate[1] ? ParseDate(this.actionDate[1]) : null
        } else {
          this.query.deliveryFinishStartDate = null
          this.query.deliveryFinishEndDate = null
        }

        if (this.qualityCheckDate.length) {
          this.query.qualityCheckStartDate = this.qualityCheckDate[0] ? ParseDate(this.qualityCheckDate[0]) : null
          this.query.qualityCheckEndDate = this.qualityCheckDate[1] ? ParseDate(this.qualityCheckDate[1]) : null
        } else {
          this.query.qualityCheckStartDate = null
          this.query.qualityCheckEndDate = null
        }
        this.query.assetIdList = JSON.stringify(this.assetIds)
        this.query.createCompanyId = this.createCompanyId
        this.query.mspTaskTypeList = JSON.stringify(this.mspTaskTypes)
        getmspfeeitempage(this.query).then((res) => {
          res.list.map((item) => {
            item._checked = that.chkfeeitemIds.includes(item.feeitemId)
            return item
          })
          that.currentPageAllItemIds = res.list.map((item) => {
            return item.feeitemId
          })
          that.list = res.list
          that.total = res.totalRow
          that.lastPage = res.lastPage
        })
      }
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initItemArray()
    },

    handlePageSizeChanged (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.initItemArray()
    },
    reloadList () {
      this.query.pageNumber = 1
      this.initItemArray()
    },
    // 选中的结算项变更
    selectionChange (selection) {
      const that = this
      const selectionIds = selection.map((item) => {
        return item.feeitemId
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach((item) => {
        // 列是否是选中状态
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.push(item)
          }
        } else {
          // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.splice(that.chkfeeitemIds.indexOf(item), 1)
          }
        }
      })
    },
    // 确定添加结算项
    confirm () {
      const that = this
      if (that.chkfeeitemIds.length) {
        const data = {
          billId: that.billId,
          feeitemType: that.query.feeitemType,
          feeitemListJsonStr: JSON.stringify(that.chkfeeitemIds),
          action: 1
        }
        batchaddorremovefeeitemlist(data).then((res) => {
          if (res && !res.errcode) {
            that.showFeeitemsModal = false
            that.$store.commit('set_settlement_update', new Date())
          }
        })
      } else {
        that.$Notice.info({ desc: '请至少选择一个结算项！' })
      }
    }
  },
  computed: {
    publisherId () {
      return this.$store.getters.token.userInfo.publisherId
    }
  }
}
</script>
